"use client";

import { captureException } from "@sentry/nextjs";
import { useEffect } from "react";

export default function GlobalError({
  error,
}: {
  error: Error & { digest?: string };
}) {
  useEffect(() => {
    captureException(error);
  }, [error]);

  return (
    <html>
      <body>
        <main className="w-full min-h-screen overflow-y-scroll flex flex-col items-center justify-between p-24 gap-24">
          <div>
            <h1 className="text-2xl">Character Experts</h1>
          </div>
          <div
            className="w-full flex flex-col items-center justify-start"
            style={{ flexGrow: 1 }}
          >
            <p>A critical error occurred</p>
          </div>
        </main>
      </body>
    </html>
  );
}
